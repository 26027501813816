@import './style/variables';

html,
body {
  touch-action: manipulation;
}

body {
  overflow: hidden;
  font-family: $default-font;
  color: $default-text-color;
  position: relative;
  .grecaptcha-badge {
    visibility: hidden;
  }
}

#root {
  height: 100%;
}

// remove scrollbar

::-webkit-scrollbar,
::-webkit-scrollbar-button,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-corner,
::-webkit-resizer {
  display: none;
  overflow: hidden;
  position: relative;
}

// remove tap highlight color
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

// custom style for checkbox and radio.
.ui.checkbox input:checked ~ label:after {
  color: $action-button-text-color !important;
}
.ui.checkbox label:before {
  border: 1px solid $action-button-text-color !important;
}

.ui.radio.checkbox input:checked ~ label:after {
  background-color: $action-button-text-color !important;
}

// Hide number input spinners (counter controls)
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
